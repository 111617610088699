import { mapActions, mapGetters, mapMutations } from 'vuex'
import general from '../general/index.vue'
import countries from '../countries/index.vue'
import seo from '../seo/index.vue'
import translations from '../translations/index.vue'

export default {
  name: 'setting-education-categories-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general'
    }
  },
  components: {
    translations,
    countries,
    general,
    seo
  },
  watch: {
    openDialogView () {
      this.dialog = this.openDialogView
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'newsCategories/currentItem',
      openDialogView: 'newsCategories/openDialogView',
      basicLocales: 'setting/basicLocales',
      basicCountries: 'setting/basicCountries',
      editId: 'newsCategories/editId',
      isLoading: 'newsCategories/isLoading',
      updateSeoLoading: 'newsCategories/updateSeoLoading'
    }),
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        {
          name: 'Countries',
          slug: 'countries',
          id: 2,
          disabled: !this.currentItem
        },
        {
          name: 'Translations',
          slug: 'translations',
          id: 3,
          disabled: !this.currentItem
        },
        {
          name: 'SEO',
          slug: 'seo',
          id: 4,
          disabled: !this.currentItem
        }
      ]
    }
  },
  created () {
    if (!this.basicLocales.length) {
      this.fetchBasicLocales()
    }
    if (!this.basicCountries.length) {
      this.fetchBasicCountries()
    }
    if (this.editId) {
      this.fetchItem()
    }
  },
  methods: {
    ...mapActions({
      fetchItem: 'newsCategories/GET_LIST_ITEM',
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES',
      fetchBasicCountries: 'setting/GET_BASIC_COUNTRIES'
    }),
    ...mapMutations({
      changeDialogView: 'newsCategories/CHANGE_DIALOG_VIEW',
      changeEditId: 'newsCategories/CHANGE_EDIT_ID',
      setContent: 'newsCategories/SET_ITEM',
      setBasicCountries: 'setting/SET_BASIC_COUNTRIES'
    }),
    changeCloseDialog () {
      this.dialog = false
      this.changeDialogView(false)
      this.changeEditId('')
    },

    save () {
      this.$refs.general.$emit('event-save')
    }
  },
  destroyed () {
    this.setContent(null)
    this.setBasicCountries([])
  }
}
